<template>
  <div id="work" class="px-4 md:px-40 flex flex-col justify-center ">
      <div class="header text-center text-3xl md:text-7xl font-bold">MY WORK SO FAR...</div>
        <!-- HBM -->
        <div class="md:flex justify-center mt-28 md:mt-40 mb-10 md:mb-20 items-center">
            <div class="md:w-48p">
                <a href="https://hbm.studio/" target="_blank">
                    <img class="cursor-pointer mx-auto" src="../assets/mockups/hbm.png" alt="">
                </a>
            </div>
            <div class="md:w-48p mt-10 md:mt-0 text-center">
                <div class="text-3xl md:text-5xl relative mx-auto" style="width:fit-content;">
                    HBM
                </div>
                <div class="text-base md:text-xl text-main my-4">Lead Front-End Developer</div>
                <div class="px-0 md:px-12 text-base md:text-xl max-w-lg mx-auto">HBM is a platform as a service that allows you to quickly build and deploy a feature-rich, customizable <span style="white-space:nowrap;">E-commerce</span> mobile & web app with no code at all. The first of it's kind in the Middle-East.</div>
                <div class="tags mt-4 mb-6">
                    <div class="tag">Vue</div>
                    <div class="tag">Nuxt</div>
                    <div class="tag">JAVASCRIPT</div>
                    <div class="tag">SCSS</div>
                    <div class="tag">Tailwind</div>
                </div>
                <div class="flex justify-center items-center">
                    <!-- <div class="mx-4 text-sm md:text-base work-links opacity-25 cursor-default">Code</div> -->
                    <a href="https://hbm.studio/" target="_blank" class="mx-4 cursor-pointer text-sm md:text-base work-links">Preview</a>
                    <!-- <a href="https://product-module.34ml.com/" target="_blank" class="mx-4 cursor-pointer text-sm md:text-base work-links">Preview</a> -->
                </div>
            </div>
        </div>
        <!-- Check24 -->
        <div class="md:flex flex-row-reverse justify-center my-10 md:my-20 items-center">
            <div class="md:w-48p">
                <a href="https://www.check24.de" target="_blank">
                    <img class="cursor-pointer mx-auto max-h-80 md:max-h-96" src="../assets/mockups/check24.png" alt="">
                </a>
            </div>
            <div class="md:w-48p mt-10 md:mt-0 text-center">
                <div class="text-3xl md:text-5xl relative mx-auto" style="width:fit-content;">
                    CHECK24
                </div>
                <div class="text-base md:text-xl text-main my-4">Senior Front-End Developer</div>
                <div class="px-0 md:px-12 text-base md:text-xl max-w-lg mx-auto">A revolutionary open banking mobile web application to automate bank account closures and migrations easily.</div>
                <div class="tags mt-4 mb-6">
                    <div class="tag">React</div>
                    <div class="tag">TYPESCRIPT</div>
                    <div class="tag">SCSS</div>
                </div>
                <div class="flex justify-center items-center">
                    <!-- <div class="mx-4 text-sm md:text-base work-links opacity-25 cursor-default">Code</div> -->
                    <div class="mx-4 text-sm md:text-base work-links opacity-25 cursor-default">Preview</div>
                </div>
            </div>
        </div>
        <!-- Inploy -->
        <div class="md:flex justify-center my-10 md:my-20 items-center">
            <div class="md:w-48p">
                <a href="https://www.inploy.me" target="_blank">
                    <img class="cursor-pointer mx-auto" src="../assets/mockups/inploy.png" alt="">
                </a>
            </div>
            <div class="md:w-48p mt-10 md:mt-0 text-center">
                <div class="text-3xl md:text-5xl relative mx-auto" style="width:fit-content;">
                    Inploy
                </div>
                <div class="text-base md:text-xl text-main my-4">Sole Front-End Developer</div>
                <div class="px-0 md:px-12 text-base md:text-xl max-w-lg mx-auto">Inploy is an online marketplace that connects businesses to premium freelance talent & SMEs.</div>
                <div class="tags mt-4 mb-6">
                    <div class="tag">Vue</div>
                    <div class="tag">JAVASCRIPT</div>
                    <div class="tag">SCSS</div>
                    <div class="tag">Tailwind</div>
                </div>
                <div class="flex justify-center items-center">
                    <!-- <div class="mx-4 text-sm md:text-base work-links opacity-25 cursor-default">Code</div> -->
                    <a href="https://www.inploy.me" target="_blank" class="mx-4 cursor-pointer text-sm md:text-base work-links">Preview</a>
                </div>
            </div>
        </div>
        <!-- Isqan -->
        <div class="md:flex flex-row-reverse justify-center my-10 md:my-20 items-center">
            <div class="md:w-48p">
                <a href="https://www.isqan.com" target="_blank">
                    <img class="cursor-pointer mx-auto" src="../assets/mockups/isqan.png" alt="">
                </a>
            </div>
            <div class="md:w-48p mt-10 md:mt-0 text-center">
                <div class="text-3xl md:text-5xl relative mx-auto" style="width:fit-content;">
                    Isqan
                </div>
                <div class="text-base md:text-xl text-main my-4">Sole Front-End Developer</div>
                <div class="px-0 md:px-12 text-base md:text-xl max-w-lg mx-auto">Isqan uses groundbreaking data to help you find real estate all over Egypt making it as easy and convenient as possible.</div>
                <div class="tags mt-4 mb-6">
                    <div class="tag">Angular</div>
                    <div class="tag">TYPESCRIPT</div>
                    <div class="tag">SCSS</div>
                    <div class="tag">BOOTSTRAP</div>
                    <div class="tag">LARAVEL</div>
                </div>
                <div class="flex justify-center items-center">
                    <!-- <div class="mx-4 text-sm md:text-base work-links opacity-25 cursor-default">Code</div> -->
                    <a href="https://www.isqan.com" target="_blank" class="mx-4 cursor-pointer text-sm md:text-base work-links">Preview</a>
                </div>
            </div>
        </div>
        <!-- Colors -->
        <div class="md:flex justify-center my-10 md:my-20 items-center">
            <div class="md:w-48p">
                <a href="https://colorsbeauty.com/" target="_blank">
                    <img class="cursor-pointer mx-auto" src="../assets/mockups/colors.png" alt="">
                </a>
            </div>
            <div class="md:w-48p mt-10 md:mt-0 text-center">
                <div class="text-3xl md:text-5xl relative mx-auto" style="width:fit-content;">
                    Colors Beauty
                </div>
                <div class="text-base md:text-xl text-main my-4">Sole Front-End Developer</div>
                <div class="px-0 md:px-12 text-base md:text-xl max-w-lg mx-auto">Colors is a chain of 6 stores, along with a strong e-commerce platform, supplying a wide rage of high-end cosmetic brands and products to the Egyptian consumers</div>
                <div class="tags mt-4 mb-6">
                    <div class="tag">Vue</div>
                    <div class="tag">JAVASCRIPT</div>
                    <div class="tag">SCSS</div>
                    <div class="tag">Tailwind</div>
                </div>
                <div class="flex justify-center items-center">
                    <!-- <div class="mx-4 text-sm md:text-base work-links opacity-25 cursor-default">Code</div> -->
                    <a href="https://colorsbeauty.com/" target="_blank" class="mx-4 cursor-pointer text-sm md:text-base work-links">Preview</a>
                </div>
            </div>
        </div>
        <!-- Underlie -->
        <div class="md:flex flex-row-reverse justify-center my-10 md:my-20 items-center">
            <div class="md:w-48p">
                <a href="https://underlie.tech/" target="_blank">
                    <img class="cursor-pointer mx-auto" src="../assets/mockups/underlie.png" alt="">
                </a>
            </div>
            <div class="md:w-48p mt-10 md:mt-0 text-center">
                <div class="text-3xl md:text-5xl relative mx-auto" style="width:fit-content;">
                    Underlie
                </div>
                <div class="text-base md:text-xl text-main my-4">Sole Developer <br><span class="text-white">&</span> UI Designer</div>
                <div class="px-0 md:px-12 text-base md:text-xl max-w-lg mx-auto">Underlie leverages open-api technology and machine learning to empower banks and fintech companies to provide their customers with better services and benefits.</div>
                <div class="tags mt-4 mb-6">
                    <div class="tag">VUE</div>
                    <div class="tag">JAVASCRIPT</div>
                    <div class="tag">SCSS</div>
                    <div class="tag">Tailwind</div>
                    <div class="tag">FIREBASE</div>
                </div>
                <div class="flex justify-center items-center">
                    <!-- <div class="mx-4 text-sm md:text-base work-links opacity-25 cursor-default">Code</div> -->
                    <a href="https://underlie.tech/" target="_blank" class="mx-4 cursor-pointer text-sm md:text-base work-links">Preview</a>
                </div>
            </div>
        </div>
        <!-- EHAF -->
        <div class="md:flex justify-center my-10 md:my-20 items-center">
            <div class="md:w-48p">
                <a href="http://ehafws.com/index.html" target="_blank">
                    <img class="cursor-pointer mx-auto" src="../assets/mockups/ehaf.png" alt="">
                </a>
            </div>
            <div class="md:w-48p mt-10 md:mt-0 text-center">
                <div class="text-3xl md:text-5xl relative mx-auto" style="width:fit-content;">
                    EHAF Construction
                </div>
                <div class="text-base md:text-xl text-main my-4">Sole Developer <br><span class="text-white">&</span> UI Designer</div>
                <div class="px-0 md:px-12 text-base md:text-xl max-w-lg mx-auto">EHAF is a world-class multi-disciplinary consultancy firm, providing architectural and engineering services in more than 12 countries, through 8 branches.</div>
                <div class="tags mt-4 mb-6">
                    <div class="tag">JAVASCRIPT</div>
                    <div class="tag">JQUERY</div>
                    <div class="tag">SCSS</div>
                    <div class="tag">BOOTSTRAP</div>
                    <div class="tag">FIREBASE</div>
                </div>
                <div class="flex justify-center items-center">
                    <!-- <div class="mx-4 text-sm md:text-base work-links opacity-25 cursor-default">Code</div> -->
                    <a href="http://ehafws.com/index.html" target="_blank" class="mx-4 cursor-pointer text-sm md:text-base work-links">Preview</a>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>