<template>
  <div id="skills" class="px-4 md:px-40 flex flex-col md:justify-around pb-20 md:pb-40">
      <div class="header text-center text-3xl md:text-7xl font-bold">THINGS I KNOW</div>
      <div class="flex justify-center items-center flex-wrap mt-10 md:mt-20 md:flex-grow">
          <img v-tooltip.top="{content: 'Vue', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/vue.svg" class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Nuxt', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/nuxt.svg" class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'React', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/react.svg" class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Next.JS', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/next.svg" class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Node.Js', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/node.svg" class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Angular', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/angular.svg" class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Javascript', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/javascript.svg" class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <!-- <img v-tooltip.top="{content: 'Jquery ', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/jquery.svg" class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt=""> -->
          <img v-tooltip.top="{content: 'Typescript', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/typescript.svg" class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'SASS', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/sass.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Tailwind CSS', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/tailwind.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Bootstrap', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/bootstrap.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Laravel', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/laravel.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Laravel Dusk', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/dusk.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'MySQL', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/sql.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Firebase', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/firebase.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Git', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/git.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Jira', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/jira.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Figma', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/figma.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Adobe Photoshop', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/photoshop.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
          <img v-tooltip.top="{content: 'Adobe Illustrator', offset: 10, classes: 'tooltip-custom', hideOnTargetClick: false}" src="../assets/skills/illustrator.svg"  class="skill-icon h-10 md:h-20 mx-4 md:mx-8 my-6 md:my-12" alt="">
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>