<template>
  <div id="contact" class="px-4 md:px-40 flex flex-col justify-center items-center">
      <div class="contact-content flex flex-col items-center justify-center">
        <div class="header text-center text-3xl md:text-7xl font-bold">GET IN TOUCH!</div>
        <div class="text-xl md:text-7xl text-center font-bold text-main mt-16 mb-10 md:my-20">I'm available for freelance work.</div>
        <a class="text-xl md:text-7xl text-center font-bold w-full transition hover:text-main" href="mailto:contact@hashimsalem.com">contact@hashimsalem.com</a>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>